export const AUTHORIZATION_KEY = "receiptmaker_token";
export const DEFAULT_SENDER_EMAIL = "no-reply@receiptmaker.io";

export const DEFAULT_RECEIPT_PREFIX = "IEC";
export const DEFAULT_RECEIPT_TITLE = "Receipt";

export const DEFAULT_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
};

export const CURRENCIES = [
  {
    label: "USD",
    value: "usd",
  },
];

export const GOOGLE_FONT_FAMILIES = [
  {
    label: "Roboto",
    value: "roboto",
  },
  {
    label: "Open Sans",
    value: "open_sans",
  },
  {
    label: "Lato",
    value: "lato",
  },
  {
    label: "Montserrat",
    value: "montserrat",
  },
  {
    label: "Oswald",
    value: "oswald",
  },
  {
    label: "Source Sans Pro",
    value: "sans_pro",
  },
  {
    label: "Raleway",
    value: "raleway",
  },
  {
    label: "PT Sans",
    value: "pt_sans",
  },
  {
    label: "Merriweather",
    value: "merriweather",
  },
  {
    label: "Mulish",
    value: "mulish",
  },
  {
    label: "Noto Sans / Serif",
    value: "noto_sans_serif",
  },
  {
    label: "Nunito Sans",
    value: "nunito_sans",
  },
  {
    label: "Concert One",
    value: "concert_one",
  },
  {
    label: "Prompt",
    value: "prompt",
  },
  {
    label: "Work Sans",
    value: "work_sans",
  },
];

export const LANGUAGES = [
  {
    label: "English",
    value: "english",
  },
];

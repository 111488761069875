/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import { isEmpty } from "_dash";
import { getIpInfo, getUserDetail } from "actions";
import { LOGGED_IN_ROUTES, NON_LOGIN_ROUTES } from "routes";

import instance from "./axios";
import AccountHook from "hooks/account";
import UserIpInfoHook from "hooks/userIpInfo";
import RouteWithSubRoutes from "./components/routeWithSubRoutes";

const App = ({ accountData, setAccountData, setUserIpInfo }) => {
  const isLoggedIn = !isEmpty(accountData);
  // eslint-disable-next-line no-eval
  const isProd = eval(process.env.REACT_APP_IS_PROD);

  const [loading, setLoading] = useState(true);
  const [appRoutes, setAppRoutes] = useState([]);

  useEffect(() => {
    getUserDetail()
      .then((response) => {
        setAccountData(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getIpInfo().then((response) => {
      setUserIpInfo(response);
      instance.defaults.headers.common["ip"] = response.ip;
    });
  }, []);

  useEffect(() => {
    const paddleInterval = window.setInterval(() => {
      if (typeof window.Paddle !== "undefined") {
        clearInterval(paddleInterval);
        if (!isProd) {
          window.Paddle.Environment.set("sandbox");
        }
        window.Paddle.Setup({
          vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID),
        });
      }
    }, 2000);
  }, []);

  useEffect(() => {
    // Yandex.Metrika counter
    if (isProd) {
      (function (m, e, t, r, i, k, a) {
        m[i] =
          m[i] ||
          function () {
            (m[i].a = m[i].a || []).push(arguments);
          };
        m[i].l = 1 * new Date();
        // eslint-disable-next-line no-unused-expressions
        (k = e.createElement(t)),
          (a = e.getElementsByTagName(t)[0]),
          (k.async = 1),
          (k.src = r),
          a.parentNode.insertBefore(k, a);
      })(
        window,
        document,
        "script",
        "https://mc.yandex.ru/metrika/tag.js",
        "ym"
      );

      // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
      ym(86782234, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
    }
  }, []);

  useEffect(() => {
    // Hotjar
    if (isProd) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 2780139, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (isLoggedIn) {
        setAppRoutes(LOGGED_IN_ROUTES);
      } else {
        setAppRoutes(NON_LOGIN_ROUTES);
      }
    }
  }, [isLoggedIn, loading]);

  if (loading) {
    return (
      <div className="app-loader">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        {appRoutes.map((route, i) => {
          return <RouteWithSubRoutes key={i} {...route} />;
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default AccountHook(UserIpInfoHook(App));
